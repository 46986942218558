.collection-modal {
  background-color: #e8e8e8;
}

.meme-collection-item-container {
  max-width: 300px;
}

.modal-canvas-container {
  border-radius: 8px;
  overflow: hidden;
  max-width: 450px;
  background-color: #303030;
}

.modal-canvas-img {
  max-height: 500px;
}




/*=== Collection Button ===*/

.collection_button {
  --main-focus: #2d8cf0;
  --font-color: #323232;
  --bg-color-sub: #dedede;
  --bg-color: #eee;
  --main-color: #323232;
  position: relative;
  width: 164px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
}

.collection_button,
.collection_button__icon,
.collection_button__text {
  transition: all 0.3s;
}

.collection_button .collection_button__text {
  transform: translateX(8px);
  color: var(--font-color);
  font-weight: 600;
}

.collection_button .collection_button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: var(--bg-color-sub);
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection_button .svg {
  width: 20px;
  fill: var(--main-color);
}

.collection_button:hover {
  background: var(--bg-color);
}

.collection_button:hover .collection_button__text {
  color: transparent;
}

.collection_button:hover .collection_button__icon {
  width: 148px;
  transform: translateX(0);
}

.collection_button:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px var(--main-color);
}

