/*========================== Font Faces ==========================*/

@font-face {
  font-family: 'Samsung Sharp Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Regular'), url(../../assets/fonts/samsungsharpsans.woff) format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Medium'), url(../../assets/fonts/samsungsharpsans-medium.woff) format('woff');
}

@font-face {
  font-family: 'Samsung Sharp Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Samsung Sharp Sans Bold'), url(../../assets/fonts/samsungsharpsans-bold.woff) format('woff');
}

@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: normal;
  src: url(../../assets/fonts/Bangers-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: normal;
  src: url(../../assets/fonts/DancingScript-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Honk';
  font-style: normal;
  font-weight: normal;
  src: url(../../assets/fonts/Honk-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: normal;
  src: url(../../assets/fonts/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url(../../assets/fonts/Montserrat-Regular.ttf) format('truetype');
}