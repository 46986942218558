.workspace-canvas-container {
  border-radius: 8px;
  overflow: hidden;
  max-width: 450px;
  background-color: #303030;
}

.workspace-canvas-img {
  max-height: 500px;
}

.color-input {

  width: 70px;

  input {
    width: 40px;
    height: 20px;
    border-radius: 20px;
  }
}